<template>
    <KCourse loader-class="MBcont" course-id="9" title="Эмоции" :items="items">
        <div class="MBtextcont">       
            <p>В этом уроке вас ждут одни неприятности.</p>
            <p><i>"Если они меня ждут, надо идти!"</i></p>
            <p>Все верно, идём смело навстречу всему новому и погружаемся в процесс, на самом деле он очень даже забавный!</p>
            <p>Потешных и легких вам неприятностей )</p>
        </div>

        <VideoView video-id="bb76ada12d574727b40661e2fd2fbce0"/>

        <div class="MBtextcont">
            <a href="/files/Emotions/3Offence.pdf" target="_blank" class="checklist" :class="$mq">
                <div class="checklist-img" :class="$mq">
                    <div class="checklist-icon">
                        <KImage :src="require('@/assets/miniland/check-list3.png')" alt="" width="100%"/>
                    </div>
                </div>
                <div class="checklist-text" :class="$mq">
                    <h3>Скачать чек-лист по обиде</h3>
                    <p>Его можно распечатать, идти четко по пунткам и тем самым разобрать свою заготовку</p>
                </div>
            </a>
        </div>

    </KCourse>
</template>

<script>
    import VideoView from "../../Widgets/VideoView";
    import KCourse from "../../Widgets/KCourse";
    import chapters from './data.js';
    import KImage from "../../Widgets/KImage";

    export default {
        name: "MBCourseHead",
        components: {
            VideoView,
            KCourse,
            KImage,
        },
        data() {
        return {
            items: chapters
        }
        }
    }
</script>

<style>

</style>